<template>
  <div class="container">
    <div class="ewm page-sub-box">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px pb-0 mb-0">
          <div class="flex space-x-2 inner">
            <div class="mt-2">기준년월</div>
            <DxDateBox
              :edit-enabled="false"
              :styling-mode="stylingMode"
              width="110"
              height="30"
              v-model="searchType.customTypes.startDt"
              type="date"
              display-format="yyyy.MM"
              dateSerializationFormat="yyyyMMdd"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              :max="searchType.customTypes.endDt"
              maxLength="7"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
              :calendar-options="{
                zoomLevel: 'year',
                minZoomLevel: 'year',
                maxZoomLevel: 'year',
              }"
              @value-changed="onDayStartChanged"
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="생성일자 기간은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <div class="mt-1">~</div>
            <DxDateBox
              :styling-mode="stylingMode"
              width="110"
              height="30"
              v-model="searchType.customTypes.endDt"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy.MM"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              maxLength="7"
              :min="searchType.customTypes.startDt"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
              :calendar-options="{
                zoomLevel: 'year',
                minZoomLevel: 'year',
                maxZoomLevel: 'year',
              }"
              @value-changed="onDayEndChanged"
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="생성일자 기간은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
          </div>
        </div>
      </div>
      <esp-dx-data-grid :data-grid="dataGrid" ref="kpiScheGrid" />

      <DxPopup
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :min-width="modal.initData ? modal.initData.width : null"
        :width="modal.initData ? modal.initData.width : null"
        :min-height="modal.initData ? modal.initData.height : null"
        :height="modal.initData ? modal.initData.height : null"
        :close-on-outside-click="false"
        v-model="modal.isOpened"
        :visible="modal.isOpened"
        @hiding="toggleDetailModal(false)"
      >
        <template #content>
          <div>
            <component :is="modal.currentComponent" :modalData="modal.sendData" v-model="modal.contentData"></component>
          </div>
        </template>

        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
            elementAttr: {
              class: 'white filled txt_S medium',
            },
            text: '닫기',
            width: '120',
            height: '40',
            onClick: () => {
              toggleDetailModal(false);
            },
          }"
        />
      </DxPopup>
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import validationEngine from 'devextreme/ui/validation_engine';
  import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
  import modalKpiSchedule from '@/pages/ewm/personal/kpi-schedule/modal-kpi-schedule.vue';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxToolbarItem,
      DxDateBox,
      DxPopup,
      DxRequiredRule,
      DxValidator,
      modalKpiSchedule,
    },
    data() {
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          customTypes: {
            startDt: getPastFromToday(11, 'months'),
            endDt: getPastFromToday(0, 'days'),
          },
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          sendData: null,
          contentData: null,
          initData: null,
        },
        dataGrid: {
          refName: 'kpiScheGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          // height:'500',    // 주석처리시 100%
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          showActionButtons: {
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
            operationDescriptions: {
              contains: '포함',
            },
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          /* selecting: {
					mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
					selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
					showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
				}, */
          columns: [
            {
              caption: '기준년월',
              dataField: 'ymd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              calculateCellValue: rowData => this.$_commonlib.formatDate(rowData.ymd, 'YYYY-MM-DD', 'YYYY.MM'),
            },
            {
              caption: '성과실적명',
              dataField: 'scheNm',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '점수',
              dataField: 'score',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '순위',
              dataField: 'rank',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '상세보기',
              dataField: '',
              alignment: 'center',
              width: 130,
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const button = new DxButton({
                  propsData: {
                    elementAttr: { class: 'btn_XS white light_filled mr-0' },
                    text: '보기',
                    width: 80,
                    height: 30,
                    onClick: () => {
                      const kpiPerformScheId = options.data.kpiPerformScheId;
                      vm.onOpenModal(
                        'modalKpiSchedule',
                        {
                          title: '성과실적상세',
                          width: 1200,
                          height: 300,
                        },
                        {
                          kpiPerformScheId: kpiPerformScheId,
                        },
                      );
                    },
                  },
                });

                button.$mount();
                container.append(button.$el);
              },
            },
          ],
        },
      };
    },
    computed: {
      searchValidationGroup: function () {
        return this.$refs['searchValidationGroup'].instance;
      },
    },
    methods: {
      onOpenModal(componentNm, componentInitData, sendData) {
        this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
        this.modal.initData = componentInitData; //set init modal templet
        this.modal.sendData = sendData; //set channelObj

        this.toggleDetailModal(true);
      },

      /** @description: 모달 열기/닫기 */
      toggleDetailModal(isOpen = true) {
        this.modal.isOpened = isOpen;
      },

      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        if (!validationEngine.validateGroup('validationSearch').isValid) {
          return;
        }

        const payload = {
          actionname: 'EWM_PERSONAL_PERFORMANCE_SCHEDULE_SELECT', // 마감된 성과실적 계획 조회
          data: {
            frYmd: vm.searchType.customTypes.startDt,
            toYmd: vm.searchType.customTypes.endDt,
          },
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.dataGrid.dataSource = res.data.data;
        }
      },
      onDayStartChanged(e) {
        const changed = e.value.slice(0, 6) + '01';
        this.searchType.customTypes.startDt = changed;
      },
      onDayEndChanged(e) {
        const changed = e.value.slice(0, 6) + '29';
        this.searchType.customTypes.endDt = changed;
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
